// colors
$c-primary: #ffb1ce;
$c-primary-shadow: rgba(175, 108, 129, 0.35);

$btn-light-yellow: #FFC501;
$btn-dark-yellow: #997601;
$btn-light-red: #E63933;
$btn-dark-red: #CC1F44;
$notes-light-yellow: #FFC501;
$notes-dark-yellow: #997601;
$black: #000;
$white: #fff;
$light-gray: #eee;
$silver-gray: #A1A3A6;
$header-black: #000;
$blue: #003bfc;
// 最小スマホサイズ(iphone)
$screen-iphone-width-sm: 320px;
$screen-iphone-height-sm: 568px;
// 標準スマホサイズ(android)
$screen-android-width-sm: 360px;
$screen-android-height-sm: 640px;
// modal固定幅 360pxの80%=288px
$modal-fixed-width: 288px;
$modal-fixed-width-walking-info: 270px;
$modal-fixed-on-board-width: 90.28%;
// modal固定高さ
$modal-fixed-height-full: 100%;
$modal-fixed-height-large: 85%; // largeは一部レスポンシブあり。 640pxの80%=512px
$modal-fixed-height-middle: 400px;
$modal-fixed-height-middle-high: 430px;
$modal-fixed-height-small: 385px;
$modal-fixed-height-half: 350px;
$modal-fixed-height-half-mp: 300px; // mpのimg中間サイズ用
$modal-fixed-height-mini: 140px;
$modal-fixed-height-small: 130px;
$modal-fixed-height-tiny: 160px;
$modal-fixed-height-footer-balloon: 20%;
$modal-fixed-height-footer-balloon-middle: 220px;
$modal-fixed-height-comp-modal-walking-info: 28px;
$modal-fixed-height-footer-middle: 230px;
//発進時、下車時の画面に出る白背景ポップアップのサイズ
$comp-m11-riding-popup-size: 198px;
$comp-walking-info-drop-off-popup-size: 320px;
// ヘッダーのtopからのmargin
$head-margin-top: 5px;
//乗車中-後_乗車中画面に出る白背景ポップアップのサイズ
$comp-on-board-popup-size: 250px;
//発進時、下車時の画面上部に出る白背景ポップアップの下に出る情報バーの表示位置(トップマージン+ポップアップサイズ)
$comp-walking-info-riding-top: $comp-m11-riding-popup-size + $head-margin-top;
$comp-walking-info-drop-off-arrival-top: $comp-walking-info-drop-off-popup-size + $head-margin-top;

// rams追加
$c-menubtn: #333;
$c-backbtn: $c-primary;
$c-commonbtn: #666;
$c-commonbtn-text: #FFF;
$c-button-blue: #23b9ff;
$c-primary-strong: #ff639d;
$radius-s: 5px;
$radius-m: 8px;

//有償化
$c-night-premium-text: #fd267c;

@function widthExchange($value) {
  @return calc(#{$value}/10.8*1vw);
}

@function heightExchange($value) {
  @return calc(#{$value}/22.08 * (100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))/100);
}

@function heightFixExchange($value) {
  @return calc(#{$value}/22.08*7.67px);
}